import { createApp } from "vue";
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import PrimeVue from "primevue/config";
import { createPinia } from 'pinia'
import 'vuetify/dist/vuetify.min.css';


const app = createApp(App);
const pinia = createPinia()

app.use(router);
app.use(vuetify);
app.use(PrimeVue, { ripple: true });
app.use(pinia)
app.mount("#app");
