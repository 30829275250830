import { createRouter, createWebHistory } from 'vue-router';
import { cookieAsObject } from '@/utils/cookieToObject';
import { userServices } from '@/services/userService';


async function checkAuth(to, from, next) {
  try {
    const cookieObject = cookieAsObject(document.cookie);
    const authorization = cookieObject.Authorization;

    if (!authorization) {
      return next({ name: 'LoginView' });
    }

    const response = await userServices.checkUserData(authorization);

    // Verificar OTP
    if (!response.data.otp_verified && to.name !== 'VerifyOTP') {
      return next({ name: 'VerifyOTP' });
    }

    // Verificar aceptación de términos
    if (!response.data.check_read_all_documents && to.name !== 'AcceptTerms') {
      return next({ name: 'AcceptTerms' });
    }

    // Verificar roles permitidos
    const allowedRoles = to.meta.allowedRoles;
    if (allowedRoles && !allowedRoles.includes(response.data.role)) {
      return next({ name: 'HomeView' }); // Redirigir si no tiene permiso
    }

    next(); // Permitir la navegación si todo está bien
  } catch (error) {
    console.error(error);
    return next({ name: 'ErrorPage' });
  }
}

const routes = [
  { path: '/', redirect: { name: 'LoginView' } }, // Redirección a Login
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/checkEmail',
    name: 'CheckEmail',
    component: () => import('@/views/CheckEmail.vue'),
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/home/userProfile',
    name: 'UserProfile',
    component: () => import('@/views/UserProfile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/library',
    name: 'LibraryView',
    component: () => import('@/views/LibraryView.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Viewer', 'Updater', 'Client'] },
  },
  {
    path: '/library/document/:id/edit',
    name: 'EditDocument',
    component: () => import('@/views/EditDocument.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Viewer', 'Updater', 'Client'] },
  },
  {
    path: '/file/anonymize',
    name: 'AnonymizationView',
    component: () => import('@/views/AnonymizationView.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Updater'] },
  },
  {
    path: '/library/uploadFile',
    name: 'UploadFile',
    component: () => import('@/views/UploadFile.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Updater'] },
  },
  {
    path: '/audit_trail',
    name: 'AuditTrail',
    component: () => import('@/views/AuditTrail.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Viewer', 'Updater', 'Client'] },
  },
  {
    path: '/user_audit_trail',
    name: 'UserAuditTrail',
    component: () => import('@/views/UserAuditTrail.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Admin', 'Superadmin'] },
  },
  {
    path: '/events_audit_trail',
    name: 'EventAuditTrail',
    component: () => import('@/views/EventAuditTrail.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Admin', 'Superadmin'] },
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: () => import('@/views/AdminView.vue'),
    meta: { requiresAuth: true, allowedRoles: ['Admin', 'Superadmin'] },
  },
  {
    path: '/verify_otp',
    name: 'VerifyOTP',
    component: () => import('@/views/VerifyOTPView.vue'),
  },
  {
    path: '/accept_terms',
    name: 'AcceptTerms',
    component: () => import('@/views/AcceptTermsView.vue'),
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    return checkAuth(to, from, next);
  }
  next();
});

export default router;
