const API_BASE_URL = process.env.VUE_APP_BASE_URL;

const defaultHeaders = (authorization) => ({
  Authorization: `Token ${authorization}`,
  'Content-Type': 'application/json',
});

const filesHeaders = (authorization) => ({
  Authorization: `Token ${authorization}`,
  'Content-Type': 'multipart/form-data',
});

export {
  API_BASE_URL,
  defaultHeaders,
  filesHeaders,
};
