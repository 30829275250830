import { coreServices } from './coreService';

import { API_BASE_URL, defaultHeaders } from './apiConfig';

export const userServices = {
  async Login(data) {
    const url = `${API_BASE_URL}/users/login/`;
    return coreServices.post(url, data);
  },

  async VerifyUser(data) {
    const url = `${API_BASE_URL}/users/verify-user/`;
    return coreServices.post(url, data);
  },

  checkUserData(authorization) {
    const url = `${API_BASE_URL}/users/users/me/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },

  resetPassword(formData) {
    const url = `${API_BASE_URL}/api/password-reset/`;
    return coreServices.post(url, formData);
  },

  changePassword(authorization, formData) {
    const url = `${API_BASE_URL}/api/password/change/`;
    return coreServices.post(url, formData, defaultHeaders(authorization));
  },

  getUsersList(authorization, study) {
    const url = `${API_BASE_URL}/users/users/`;
    const params = { study };
    return coreServices.get(url, defaultHeaders(authorization), params);
  },

  deleteUser(authorization, userId, deleteReason) {
    const url = `${API_BASE_URL}/users/users/${userId}/?reason=${encodeURIComponent(deleteReason)}`;
    return coreServices.delete(url, defaultHeaders(authorization));
  },

  updateUser(authorization, userId, userData) {
    const url = `${API_BASE_URL}/users/users/${userId}/`;
    return coreServices.put(url, { userData }, defaultHeaders(authorization));
  },

  createUser(authorization, newUserData) {
    const url = `${API_BASE_URL}/users/users/`;
    return coreServices.post(url, { newUserData }, defaultHeaders(authorization));
  },
  getUnreadDocument(authorization) {
    const url = `${API_BASE_URL}/users/unread-documents/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  getUserApplicationDocumentsReads(authorization) {
    const url = `${API_BASE_URL}/users/application-documentations/read-documents/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  downloadDocument(authorization, documentId) {
    const url = `${API_BASE_URL}/users/application-documentations/${documentId}/download/`;
    return coreServices.get_blob(url, defaultHeaders(authorization));
  },
  getUnreadDocumentMetadata(authorization) {
    const url = `${API_BASE_URL}/users/application-documentations/metadata/`;
    return coreServices.get(url, defaultHeaders(authorization));
  },
  markDocumentAsOpen(authorization, documentId) {
    const url = `${API_BASE_URL}/users/application-documentations/mark-as-open/`;
    return coreServices.post(url, { document_id: documentId }, defaultHeaders(authorization));
  },
  markDocumentAsRead(authorization, documentId) {
    const url = `${API_BASE_URL}/users/application-documentations/mark-as-read/`;
    return coreServices.post_with_errors(
      url, { document_id: documentId }, defaultHeaders(authorization),
    );
  },
  verifyOTP(authorization, code) {
    const url = `${API_BASE_URL}/users/verify-otp/`;
    return coreServices.post_with_errors(url, { otp: code }, defaultHeaders(authorization));
  },
  resendOTP(authorization) {
    const url = `${API_BASE_URL}/users/resend-otp/`;
    return coreServices.post_with_errors(url, {}, defaultHeaders(authorization));
  }
};
